<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Titel</label>
          <input type="text" class="uk-input" v-model="form.title" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Inhalt</label>
          <input type="text" class="uk-input" v-model="form.content" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link</label>
          <input type="text" class="uk-input" placeholder="http://" v-model="form.link" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link Ziel</label>
          <select class="uk-select" v-model="form.link_target">
            <option value="_self">Gleiches Fenster</option>
            <option value="_blank">Neues Fenster</option>
          </select>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      form: {},
      icons: []
    };
  },
  mounted() {
    this.form = this.data.props;
    axios.get(`./../../../assets/json/icons.json`).then(response => {
      this.icons = response.data;
    });
  },
  methods: {
    openIconModal() {
      this.$refs.selectIconModal.open();
    },
    selectIcon(icon) {
      this.form.icon = icon;
      this.$refs.selectIconModal.close();
    }
  }
};
</script>
