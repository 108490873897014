<template>
  <div>
    <form class="uk-form-stacked" v-if="!editList">
      <div class="uk-width-1-1">
        <div class="uk-background-default uk-padding-small">
          <div class="uk-margin">
            <label class="uk-form-label">Liste</label>
            <div v-for="(item, index) in localData.items" :key="index" class="uk-margin-small">
              <div class="uk-flex uk-flex-middle">
                <button
                  type="button"
                  class="uk-button uk-flex uk-flex-middle uk-background-muted uk-text-left"
                  @click.prevent="edit(item)"
                >
                  {{ item.props.title }}
                  <span data-uk-icon="chevron-right"></span>
                </button>
                <a
                  href="#"
                  uk-icon="trash"
                  class="uk-margin-left uk-text-muted"
                  @click.prevent="removeItem(index)"
                ></a>
              </div>
            </div>
            <div class="uk-margin">
              <button
                class="uk-button-secondary uk-button uk-border-rounded uk-button-small"
                @click.prevent="add"
              >Eintrag hinzufügen</button>
            </div>
          </div>
          <hr />
          <div class="uk-grid uk-grid-divider uk-child-width-1-2@m" uk-grid>
            <div>
              <div class="uk-h5 uk-text-uppercase">Liste</div>
              <div class="uk-margin">
                <label class="uk-form-label">Style</label>
                <select class="uk-select uk-margin-small-bottom" v-model="form.list_style">
                  <option value>Ohne</option>
                  <option value="divider">Unterstrichen</option>
                  <option value="striped">Gestreift</option>
                </select>
                <label>
                  <input type="checkbox" class="uk-checkbox" v-model="form.list_items_padding" /> Mehr Abstand zwischen den Zeilen
                </label>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Layout</label>
                <select class="uk-select" v-model="form.list_layout">
                  <option value="grid">2 Spalten Grid</option>
                  <option value="stacked">Untereinander</option>
                </select>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Breite</label>
                <select class="uk-select" v-model="form.list_width" :disabled="form.list_layout === 'stacked'">
                  <option value="auto">Automatisch</option>
                  <option value="small">Klein</option>
                  <option value="medium">Medium</option>
                  <option value="expand">Expandiert</option>
                </select>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Abstand</label>
                <select class="uk-select" v-model="form.list_gutter" :disabled="form.list_layout === 'stacked'">
                  <option value="small">Klein</option>
                  <option value="medium">Medium</option>
                  <option value>Standart</option>
                  <option value="large">Groß</option>
                </select>
              </div>
            </div>
            <div>
              <div class="uk-h5 uk-text-uppercase">Titel</div>
              <div class="uk-margin">
                <label class="uk-form-label">Style</label>
                <select class="uk-select" v-model="form.list_title_style">
                  <option value>None</option>
                  <option value="text-bold">Fett</option>
                  <option value="text-muted">Gedämpft</option>
                  <option value="text-small">Klein</option>
                  <option value="h1">H1</option>
                  <option value="h2">H2</option>
                  <option value="h3">H3</option>
                  <option value="h4">H4</option>
                  <option value="h5">H5</option>
                  <option value="h6">H6</option>
                </select>
                <label>
                  <input type="checkbox" class="uk-checkbox" v-model="form.list_title_colon" /> Doppelpunkt hinzufügen
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-default" @click.prevent="showSettings = !showSettings">
            Einstellungen
            <span uk-icon="arrow-right" v-if="!showSettings"></span>
            <span uk-icon="arrow-down" v-if="showSettings"></span>
          </button>
        </div>
      </div>
      <div class="uk-width-1-1 uk-margin" v-if="showSettings">
        <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
          <general-settings :form="form" />
        </div>
      </div>
    </form>
    <div class="uk-width-1-1 uk-margin" v-else>
      <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
        <description-list-item-edit :data="this.editListData" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DescriptionListItemEdit from "./DescriptionListItemEdit";
import GeneralSettings from "./../GeneralSettings";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    DescriptionListItemEdit,
    GeneralSettings,
  },
  data() {
    return {
      form: {},
      localData: {},
      editList: false,
      editListData: null,
      icons: [],
      showSettings: false
    };
  },
  mounted() {
    this.form = this.data.props;
    this.localData = this.data;
    axios.get(`./../../../assets/json/icons.json`).then(response => {
      this.icons = response.data;
    });
  },
  methods: {
    async add() {
      let defaultProps = await axios.get(
        `./../../../assets/json/defaultProps/listItem.json`
      );
      const elemObj = {
        type: "list_item",
        props: defaultProps.data
      };
      this.localData.items.push(elemObj);
      this.edit(elemObj);
    },
    edit(item) {
      this.editList = true;
      this.editListData = item;
    },
    removeItem(index) {
      this.localData.items.splice(index, 1);
    },
    openIconModal() {
      this.$refs.selectIconModal.open();
    },
    selectIcon(icon) {
      this.form.list_icon = icon;
      this.$refs.selectIconModal.close();
    }
  }
};
</script>
